let lockIds = [];
export default async (callBack,lockId=callBack)=>{
  if(lockIds.includes(lockId)) return
  lockIds.push(lockId);
  await callBack((time)=>{
    return new Promise((reslove)=>{
      setTimeout(()=>{
        reslove()
      },time)
    })
  });
  lockIds = lockIds.filter(item=>item!=lockId);
}