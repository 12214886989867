import Telegram from "./Telegram"
import { system_channel_api } from '@/api/index.js'
import QueryString from "qs"
import store from '@/store'
import getDevice from "@/common/getDevice"
import helper from '@/common/helper.js';
import { login_get_third_login_url_api, login_third_login_api } from "@/api/user";
const classMap = {Telegram}
class ThirdLogin {
 thirdObjs = {};
 callback = null;
 thirdInfo = {};
  constructor(thirds) {
    if (thirds) {
        for (let i = 0; i < thirds.length; i++) {
            const third = thirds[i]
            let clsName = this.capitalizeFirstLetter(third.third_sys_sign)
            if (classMap[clsName]) {
                this.thirdObjs[clsName] = new classMap[clsName](third); // 动态创建实例
            } else {
                console.warn(`Class ${clsName} does not exist.`);
            }
        }
    }
  }
  capitalizeFirstLetter(str) {
    if (!str) return '';
    str = str.toLowerCase();
    return `${str[0].toUpperCase()}${str.slice(1)}`;
  }
  
  async login(thirdInfo, callback) {
    this.thirdInfo = thirdInfo
    helper.set("thirdLoginId", thirdInfo.id);
    this.callback = callback
    let clsName = this.capitalizeFirstLetter(thirdInfo.third_sys_sign)
    let that = this
    if(that.thirdObjs[clsName]) {
        try {
            that.thirdObjs[clsName].login((user) => {
                that.loginCallback(user)
            })
        } catch (error) {
            that.loginFial()
        }
        return
    }
    await login_get_third_login_url_api({ id : thirdInfo.id, href: location.origin }).then(res => {
      if (res && res.data.code == 1) {
        if (res.data.data.url) {
            window.location.href = res.data.data.url;
        } else {
            console.error("third[" + clsName + "] login url is null");   
        }
      }
    });
  }

  // 登录回调
  loginCallback(user) {
    if (!user) {
        this.loginFial()
        return
    }
    let param = {
      id: this.thirdInfo.id, 
      code: user,
      rec_code: helper.get('share_code'),
      acid: helper.get('acid'),
      cid: helper.get('clickId'),
      device_type: getDevice()
    };
    login_third_login_api(param).then((res) => {
      this.callback && this.callback(res)
    });
  }
  
  loginFial() {
    this.callback && this.callback({
        data: {
            msg: "login failed",
            code: 0
        }
    })
  }
}
export default ThirdLogin;