<template>
  <div class="download-box" v-if="$store.state.topDownLoadIsShow">
    <div class="download-warp" @click="downApp">
      <div class="container">
        <i class="el-icon el-icon-close" @click="$store.commit('changeTopDownLoadIsShow',false)"></i>
        <img src="@/assets/images/download-top-icon.png" class="download-icon">
        <img src="@/assets/images/download-top-icon.png" class="download-icon">
        <img src="@/assets/images/download-top-icon.png" class="download-icon">
        <!-- <img src="@/assets/images/download-top-right.png" class="download-right"> -->
        <div class="download-button">
          <img src="@/assets/images/download-top-button-text.png" class="download-button-text">
          <img src="@/assets/images/download-top-button-icon.png" class="download-button-icon" :class="{ iconAnimation }" @animationend="iconAnimationend">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import getDevice from "@/common/getDevice"
export default {
  data(){
    return {
      iconAnimation: true,
      dev:""
    } 
  },
  watch:{
    "$route.path":function(){
      this.changeShow()
    },
    "$store.state.env":{
      handler(){
        this.dev = getDevice()
        this.changeShow()
      },
      immediate:true
    }
  },
  methods:{
    changeShow(){
      this.$store.commit("changeTopDownLoadIsShow",this.$route.path==="/" && (this.dev==='ANDROID_WEB' || this.dev==='IPHONE_WEB'))
    },
    iconAnimationend() {
      this.iconAnimation = false;
      setTimeout(() => this.iconAnimation = true, 1000)
    },
  },
}
</script>
<style scoped>
.download-warp {
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background-image: url('~@/assets/images/download-top-bg.png');
  background-size: 100% 100%;
  background-color: #000;
}
.download-box{
  height: 56px;
}
.container {
  display: flex;
  align-items: center;
}

.download-icon {
  width: 62px;
  height: 56px;
  animation: download-icon 1s infinite;
}
@keyframes  download-icon{
  0%{
    transform: scale(1);
  }50%{
    transform: scale(.9);
  }100%{
    transform: scale(1);
  }
}

.download-button {
  width: 90px;
  height: 35px;
  margin-left: auto;
  position: relative;
  z-index: 2;
  background-image: url(~@/assets/images/download-top-button.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
  cursor: pointer;
  user-select: none;
}

.download-right {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
}

.el-icon-close {
  font-size: 24px;
}
.download-button-text{
  width: 39px;
}
.download-button-icon{
  width: 24px;
  margin-left: 10px;
}
.iconAnimation {
    animation: download-run .5s 3;
    animation-fill-mode: both;
}
@keyframes download-run {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1)
    }
}
</style>