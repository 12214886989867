<template>
  <el-dialog :visible.sync="dialogTableVisible" :title="$t('钱包')" z-index="2015">
    <div class="dw">
      <div class="dw-card">
        <div class="dw-card-title">{{ $t("现金") }}</div>
        <div class="dw-card-price">{{ $helper.get("rule").currency.symbol }}{{ numFormat($store.state.userInfo?.balance) }}</div>
        <el-button class="button_fullwidth button_outline" v-if="$store.state.userInfo?.savings > 0">{{ $t("剩余所需流水") }} : {{ numFormat($store.state.userInfo?.savings) }}</el-button>
        <el-button class="button_fullwidth button_purple" v-else @click="showWallet" :loading="$store.state.loading.wallet">{{ $t("现在充值") }}</el-button>
      </div>
      <div class="dw-card">
        <div class="dw-card-title">{{ $t("彩金") }}</div>
        <div class="dw-card-price">{{ $helper.get("rule").currency.symbol }}{{ numFormat($store.state.userInfo?.cjbalance) }}</div>
        <el-button class="button_fullwidth button_outline" v-if="$store.state.userInfo?.cjbalance > 0">{{ $t("剩余所需流水") }} : {{  numFormat($store.state.userInfo?.cjsavings) }}</el-button>
        <el-button class="button_fullwidth button_purple" v-else @click="$router.push('/layout/edemption-code');dialogTableVisible = false">{{ $t("获取奖金") }}</el-button>
      </div>
      <img src="@/assets/images/wg-banner.png" alt="" class="dw-banner" @click="$router.push('/all-game?tab=bonus'); dialogTableVisible=false">
      <div class="dw-rule">
        <div class="dw-rule-title">{{ $t("规则") }}</div>
        <div class="dw-rule-content html" v-html="$t('双钱包规则')"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogTableVisible: false
    }
  },
  methods:{
    show(){
      this.dialogTableVisible = true
    },
    showWallet(){
			if(this.$store.state.loading.wallet) return
      this.$store.state.walletGetData++
		}
  }
}
</script>

<style scoped lang="less" src="@/assets/css/dualWallet.less"></style>