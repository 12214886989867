<template>
  <div></div>
</template>
<script>
import { game_user_transout_api } from "@/api/cybSports";
export default {
  computed: {
    // 计算是否有余额未转出
    thirdBalance() {
      return this.$store.state.userInfo ? this.$store.state.userInfo.third_balance > 0 : false;
    }
  },
  watch: {
    // 监听是否有余额未转出的变化，有的话请求接口转出余额
    thirdBalance: {
      handler(v) {
        if (v && this.$route.path !== "/game-detail") this.dialogComfirm()
      },
      immediate: true
    }
  },
  methods: {
    // 转出游戏内三方余额接口请求
    async dialogComfirm() {
      await game_user_transout_api(0)
      await this.updateUserInfo();
    },
  }
}
</script>
